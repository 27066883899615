import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element';
import VueTailwind from 'vue-tailwind';
import {
    TInput,
    TButton,
    TModal,
    TDatepicker,
    TDropdown,
} from 'vue-tailwind/dist/components';

import English from 'vue-tailwind/dist/l10n/index';
import Italian from 'vue-tailwind/dist/l10n/it';
import Spanish from 'vue-tailwind/dist/l10n/es';
import German from 'vue-tailwind/dist/l10n/de';
import French from 'vue-tailwind/dist/l10n/fr';
import translation from '@/mixins/translation.js';

import mdiVue from 'mdi-vue/v2'
import {
    mdiHumanMale,
    mdiMapMarker,
    mdiMapMarkerCircle,
    mdiMinusCircle,
    mdiPlusCircle,
} from '@mdi/js';

const icons = {
    mdiHumanMale,
    mdiMapMarker,
    mdiMapMarkerCircle,
    mdiMinusCircle,
    mdiPlusCircle,
}

import '@/index.css';

const components = {
    TInput,
    TButton,
    TModal,
    TDatepicker,
    TDropdown,
}

const settings = {
    // ...,
    't-input': {
        component: TInput,
    },
    't-dropdown': {
        component: TDropdown,
        props: {
            fixedClasses: {
                button: 'tw-flex tw-items-center tw-text-white tw-block tw-px-4 tw-py-2 tw-transition tw-duration-100 tw-ease-in-out tw-border tw-border-transparent tw-rounded tw-shadow-sm focus:tw-border-blue-500 focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-outline-none focus:tw-ring-opacity-50 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed',
                wrapper: 'tw-inline-flex tw-flex-col',
                dropdownWrapper: 'tw-relative tw-z-10',
                dropdown: 'tw-absolute tw-left-0 tw-w-56 tw-rounded tw-shadow',
                enterClass: 'tw-opacity-0 tw-scale-95',
                enterActiveClass: 'tw-transition tw-transform tw-ease-out tw-duration-100',
                enterToClass: 'tw-opacity-100 tw-scale-100',
                leaveClass: 'tw-opacity-100 tw-scale-100',
                leaveActiveClass: 'tw-transition tw-transform tw-ease-in tw-duration-75',
                leaveToClass: 'tw-opacity-0 tw-scale-95'
            },
            classes: {
                button: 'tw-bg-blue-500 hover:tw-bg-blue-600',
                dropdown: 'tw-bg-white tw-mt-1'
            },
            variants: {
                dropup: {
                    dropdown: 'tw-bg-white tw-mb-1 tw-bottom-full tw-top-auto'
                }
            }
        }
    },
    't-datepicker': {
      component: TDatepicker,
      props: {
        // classes: {...},
        // fixedClasses: {...},
        locales: {
            en: English,
            it: Italian,
            de: German,
            fr: French,
            es: Spanish,
        },
        lang: 'en',
        fixedClasses: {
            navigator: 'tw-flex',
            navigatorViewButton: 'tw-flex itw-tems-center',
            navigatorViewButtonIcon: 'tw-flex-shrink-0 tw-h-5 tw-w-5',
            navigatorViewButtonBackIcon: 'tw-flex-shrink-0 tw-h-5 tw-w-5',
            navigatorLabel: 'tw-flex tw-items-center tw-py-1',
            navigatorPrevButtonIcon: 'tw-h-6 tw-w-6 tw-inline-flex',
            navigatorNextButtonIcon: 'tw-h-6 tw-w-6 itw-nline-flex',
            inputWrapper: 'rtw-elative',
            viewGroup: 'tw-inline-flex tw-flex-wrap',
            view: 'tw-w-64',
            calendarDaysWrapper: 'tw-grid tw-grid-cols-7',
            calendarHeaderWrapper: 'tw-grid tw-grid-cols-7',
            monthWrapper: 'tw-grid tw-grid-cols-4',
            yearWrapper: 'tw-grid tw-grid-cols-4',
            input: 'tw-block tw-w-full tw-px-3 tw-py-2 tw-transition tw-duration-100 tw-ease-in-out tw-border tw-rounded tw-shadow-sm focus:tw-border-blue-500 focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-outline-none focus:tw-ring-opacity-50 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed',
            clearButton: 'tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-absolute tw-right-0 tw-top-0 tw-m-2 tw-h-6 tw-w-6',
            clearButtonIcon: 'tw-fill-current tw-h-3 tw-w-3'
          },
        classes: {
            wrapper: 'tw-flex tw-flex-col',
            dropdownWrapper: 'tw-relative tw-z-10',
            
            // tw-Dropdown tw-related tw-classes
            dropdown: 'tw-origin-top-left tw-absolute tw-rounded tw-shadow tw-bg-white tw-overflow-hidden tw-mt-1',
            enterClass: 'tw-opacity-0 tw-scale-95',
            enterActiveClass: 'tw-transition tw-transform tw-ease-out tw-duration-100',
            enterToClass: 'tw-opacity-100 tw-scale-100',
            leaveClass: 'tw-opacity-100 tw-scale-100',
            leaveActiveClass: 'tw-transition tw-transform tw-ease-in tw-duration-75',
            leaveToClass: 'tw-opacity-0 tw-scale-95',

            // tw-Wrapper tw-for tw-inline tw-calendar
            inlineWrapper: '',
            inlineViews: 'tw-rounded tw-bg-white tw-border tw-mt-1 tw-inline-flex tw-flex-col',

            // tw-Text tw-input tw-related tw-classes
            inputWrapper: '',
            input: 'tw-block tw-w-full tw-px-3 tw-py-2 tw-text-black tw-placeholder-gray-400 tw-transition tw-duration-100 tw-ease-in-out tw-bg-white tw-border tw-border-gray-300 tw-rounded tw-shadow-sm tw-focus:border-blue-500 tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:outline-none tw-focus:ring-opacity-50 tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            clearButton: 'tw-hover:bg-gray-100 tw-rounded tw-transition tw-duration-100 tw-ease-in-out tw-text-gray-600',
            clearButtonIcon: '',

            // tw-Picker tw-views
            viewGroup: '',
            view: '',

            // tw-Navigator
            navigator: 'tw-pt-2 tw-px-3',
            navigatorViewButton: 'tw-transition tw-ease-in-out tw-duration-100 tw-inline-flex tw-cursor-pointer tw-rounded-full tw-px-2 tw-py-1 tw--ml-1 tw-hover:bg-gray-100',
            navigatorViewButtonIcon: 'tw-fill-current tw-text-gray-400',
            navigatorViewButtonBackIcon: 'tw-fill-current tw-text-gray-400',

            navigatorViewButtonMonth: 'tw-text-gray-700 tw-font-semibold',
            navigatorViewButtonYear: 'tw-text-gray-500 tw-ml-1',
            navigatorViewButtonYearRange: 'tw-text-gray-500 tw-ml-1',
            navigatorLabel: 'tw-py-1',
            navigatorLabelMonth: 'tw-text-gray-700 tw-font-semibold',
            navigatorLabelYear: 'tw-text-gray-500 tw-ml-1',
            navigatorPrevButton: 'tw-transition tw-ease-in-out tw-duration-100 tw-inline-flex tw-cursor-pointer tw-hover:bg-gray-100 tw-rounded-full tw-p-1 tw-ml-2 tw-ml-auto tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            navigatorNextButton: 'tw-transition tw-ease-in-out tw-duration-100 tw-inline-flex tw-cursor-pointer tw-hover:bg-gray-100 tw-rounded-full tw-p-1 tw--mr-1 tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            navigatorPrevButtonIcon: 'tw-text-gray-400',
            navigatorNextButtonIcon: 'tw-text-gray-400',

            // tw-Calendar tw-View
            calendarWrapper: 'tw-px-3 tw-py-2',
            calendarHeaderWrapper: '',
            calendarHeaderWeekDay: 'tw-uppercase tw-text-xs tw-text-gray-500 tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center',
            calendarDaysWrapper: '',
            calendarDaysDayWrapper: 'tw-w-full tw-h-8 tw-flex tw-flex-shrink-0 tw-items-center',

            // tw-Day tw-item
            otherMonthDay: 'tw-text-sm tw-rounded-full tw-w-8 tw-h-8 tw-mx-auto tw-hover:bg-blue-100 tw-text-gray-400 tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            emptyDay: '',
            inRangeFirstDay: 'tw-text-sm tw-bg-blue-500 tw-text-white tw-w-full tw-h-8 tw-rounded-l-full',
            inRangeLastDay: 'tw-text-sm tw-bg-blue-500 tw-text-white tw-w-full tw-h-8 tw-rounded-r-full',
            inRangeDay: 'tw-text-sm tw-bg-blue-200 tw-w-full tw-h-8 tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            selectedDay: 'tw-text-sm tw-rounded-full tw-w-8 tw-h-8 tw-mx-auto tw-bg-blue-500 tw-text-white tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            activeDay: 'tw-text-sm tw-rounded-full tw-bg-blue-100 tw-w-8 tw-h-8 tw-mx-auto tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            highlightedDay: 'tw-text-sm tw-rounded-full tw-bg-blue-200 tw-w-8 tw-h-8 tw-mx-auto tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            day: 'tw-text-sm tw-rounded-full tw-w-8 tw-h-8 tw-mx-auto tw-hover:bg-blue-100 tw-disabled:opacity-50 tw-disabled:cursor-not-allowed',
            today: 'tw-text-sm tw-rounded-full tw-w-8 tw-h-8 tw-mx-auto tw-hover:bg-blue-100 tw-disabled:opacity-50 tw-disabled:cursor-not-allowed tw-border tw-border-blue-500',

            // tw-Months tw-View
            monthWrapper: 'tw-px-3 tw-py-2',
            selectedMonth: 'tw-text-sm tw-rounded tw-w-full tw-h-12 tw-mx-auto tw-bg-blue-500 tw-text-white',
            activeMonth: 'tw-text-sm tw-rounded tw-w-full tw-h-12 tw-mx-auto tw-bg-blue-100',
            month: 'tw-text-sm tw-rounded tw-w-full tw-h-12 tw-mx-auto tw-hover:bg-blue-100',

            // tw-Years tw-View
            yearWrapper: 'tw-px-3 tw-py-2',
            year: 'tw-text-sm tw-rounded tw-w-full tw-h-12 tw-mx-auto tw-hover:bg-blue-100',
            selectedYear: 'tw-text-sm tw-rounded tw-w-full tw-h-12 tw-mx-auto tw-bg-blue-500 tw-text-white',
            activeYear: 'tw-text-sm tw-rounded tw-w-full tw-h-12 tw-mx-auto tw-bg-blue-100',

            // tw-Time tw-selector tw-*Since tw-2.2*
            timepickerWrapper: 'tw-flex tw-items-center tw-px-4 tw-py-2 tw-space-x-2',
            timepickerTimeWrapper: 'tw-flex tw-items-center tw-space-x-2',
            timepickerTimeFieldsWrapper: 'tw-bg-gray-100 tw-rounded-md tw-w-full tw-text-right tw-flex tw-items-center tw-border tw-border-gray-100 tw-focus:border-blue-500 tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:outline-none tw-focus:ring-opacity-50',
            timepickerOkButton: 'tw-text-blue-600 tw-text-sm tw-uppercase tw-font-semibold tw-transition tw-duration-100 tw-ease-in-out tw-border tw-border-transparent tw-focus:border-blue-500 tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:outline-none tw-focus:ring-opacity-50 tw-rounded tw-cursor-pointer',
            timepickerInput: 'tw-text-center tw-w-8 tw-border-transparent tw-bg-transparent tw-p-0 tw-h-6 tw-text-sm tw-transition tw-duration-100 tw-ease-in-out tw-border tw-border-transparent tw-focus:border-blue-500 tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:outline-none tw-focus:ring-opacity-50 tw-rounded',
            timepickerTimeLabel: 'tw-flex-grow tw-text-sm tw-text-gray-500',
            timepickerAmPmWrapper: 'tw-relative tw-inline-flex tw-flex-shrink-0 tw-transition tw-duration-200 tw-ease-in-out tw-bg-gray-100 tw-border tw-border-transparent tw-rounded tw-cursor-pointer tw-focus:border-blue-500 tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:outline-none tw-focus:ring-opacity-50',
            timepickerAmPmWrapperChecked: 'tw-relative tw-inline-flex tw-flex-shrink-0 tw-transition tw-duration-200 tw-ease-in-out tw-bg-gray-100 tw-border tw-border-transparent tw-rounded tw-cursor-pointer tw-focus:border-blue-500 tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:outline-none tw-focus:ring-opacity-50',
            timepickerAmPmWrapperDisabled: 'tw-relative tw-inline-flex tw-flex-shrink-0 tw-transition tw-duration-200 tw-ease-in-out tw-opacity-50 tw-cursor-not-allowed',
            timepickerAmPmWrapperCheckedDisabled: 'tw-relative tw-inline-flex tw-flex-shrink-0 tw-transition tw-duration-200 tw-ease-in-out tw-opacity-50 tw-cursor-not-allowed',
            timepickerAmPmButton: 'tw-absolute tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-text-xs tw-text-gray-800 tw-transition tw-duration-200 tw-ease-in-out tw-transform tw-translate-x-0 tw-bg-white tw-rounded tw-shadow',
            timepickerAmPmButtonChecked: 'tw-absolute tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-text-xs tw-text-gray-800 tw-transition tw-duration-200 tw-ease-in-out tw-transform tw-translate-x-full tw-bg-white tw-rounded tw-shadow',
            timepickerAmPmCheckedPlaceholder: 'tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-text-xs tw-text-gray-500 tw-rounded-sm',
            timepickerAmPmUncheckedPlaceholder: 'tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-text-xs tw-text-gray-500 tw-rounded-sm',              
        }
      }    
    },
    't-button': {
        component: TButton,
        props: {
          fixedClasses: 'tw-bg-gray-300 tw-block tw-px-4 tw-py-2 tw-transition tw-duration-100 tw-ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
          classes: 'tw-text-white tw-border tw-border-transparent tw-shadow-sm tw-rounded',
          variants: {
            error: 'tw-text-white tw-border tw-border-transparent tw-rounded tw-shadow-sm',
            success: 'tw-text-white tw-bg-green-500 tw-border tw-border-transparent tw-rounded tw-shadow-sm tw-hover:bg-green-600',
            link: 'tw-text-blue-500 tw-underline tw-hover:text-blue-600'
          }
        }
    },
    't-modal': {
        component: TModal,
        props: {
            fixedClasses: {
                overlay: 'tw-z-999 tw-overflow-auto tw-scrolling-touch tw-left-0 tw-top-0 tw-bottom-0 tw-right-0 tw-w-full tw-h-full tw-fixed tw-bg-opacity-50 filter-blur-force',
                wrapper: 'tw-relative tw-mx-auto tw-z-50 tw-px-3 tw-py-12',
                modal: 'tw-overflow-visible vrelative rtw-ounded',
                body: 'tw-p-3',
                header: 'tw-border-b tw-p-3 tw-rounded-t',
                footer: ' tw-p-3 tw-rounded-b',
                close: 'tw-flex tw-items-center tw-justify-center tw-rounded-full tw-absolute tw-right-0 tw-top-0 tw--m-3 tw-h-8 tw-w-8 tw-transition tw-duration-100 tw-ease-in-out focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-outline-none focus:tw-ring-opacity-50'
            },
            classes: {
                overlay: 'tw-bg-black',
                wrapper: 'lg:tw-w-1/2 md:tw-w-1/2 xs:tw-w-full',
                modal: 'tw-bg-white tw-shadow',
                body: 'tw--3',
                header: 'tw-border-gray-100',
                footer: 'tw-bg-gray-100',
                close: 'tw-bg-gray-100 tw-text-gray-600 hover:tw-bg-gray-200',
                closeIcon: 'tw-fill-current tw-h-4 tw-w-4',
                overlayEnterClass: 'tw-opacity-0',
                overlayEnterActiveClass: 'tw-transition tw-ease-out tw-duration-100',
                overlayEnterToClass: 'tw-opacity-100',
                overlayLeaveClass: 'tw-opacity-100',
                overlayLeaveActiveClass: 'tw-transition tw-ease-in tw-duration-75',
                overlayLeaveToClass: 'tw-opacity-0',
                enterClass: '',
                enterActiveClass: '',
                enterToClass: '',
                leaveClass: '',
                leaveActiveClass: '',
                leaveToClass: ''
            },
            variants: {
                guests: {
                    wrapper: 'lg:tw-w-1/4 md:tw-w-1/4 xs:tw-w-full',
                },
                dates: {
                    wrapper: 'lg:tw-w-1/2 md:tw-w-1/2 xs:tw-w-full',
                }
            }
        }
    }
  }

Vue.use(VueTailwind, settings)
Vue.use(mdiVue, {
    icons: icons
})
Vue.mixin(translation);

Vue.use(vueCustomElement);
Vue.customElement('ciaobooking-widget', App);
Vue.config.productionTip = false;
